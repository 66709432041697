import { Component, HostListener } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CrudAction } from '@core/enums/crud-action.enum';
import { ApiService, ListOptions } from '@capturum/api';
import { FormUtil } from '@core/utils/form-util';
import { AppInjector } from '../../app-injector.service';
import { HttpRequest } from '@angular/common/http';
import { FormAction } from '@core/enums/form-action.enum';
import { FormConfigService } from '@shared/services/form-config.service';
import { FormConfig, FormHelper, FormState } from '@core/enums/form-config.enum';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@shared/modules/notification/notification.service';
import { EntityConfig } from '@core/models/entity-config.model';
import { DestroyBase } from '@core/base/destroy.class';
import { IEntityBase } from '@core/interfaces/entity-base';
import { UnsavedChangesWarningService } from '@core/services/unsaved-changes-warning.service';
import { UnsavedChangesResponse } from '@core/interfaces/unsaved-changes.interface';

@Component({
  template: '',
})
export class PopupFormComponent<EntityModel extends IEntityBase> extends DestroyBase {
  public form: UntypedFormGroup;
  public formConfig: FormConfig = {
    submitIcon$: new BehaviorSubject<string>('fa fa-check'),
    state: FormState.isIdle,
  };

  protected Actions: typeof CrudAction = CrudAction;
  protected FormAction: typeof FormAction = FormAction;
  protected visible: boolean;

  protected entityConfig: EntityConfig;

  // Injectable dependencies:
  protected formConfigService: FormConfigService<EntityModel>;
  protected translateService: TranslateService;
  protected notificationService: NotificationService;
  protected unsavedChangesWarningService: UnsavedChangesWarningService;

  constructor(protected apiService: ApiService<EntityModel>) {
    super();

    this.formConfigService = AppInjector.getInjector().get(FormConfigService);
    this.translateService = AppInjector.getInjector().get(TranslateService);
    this.notificationService = AppInjector.getInjector().get(NotificationService);
    this.unsavedChangesWarningService = AppInjector.getInjector().get(UnsavedChangesWarningService);
  }

  protected get getAction(): CrudAction {
    return FormHelper.isEdit(this.formConfig) ? this.Actions.update : this.Actions.create;
  }

  public onClose(): void {
    if (this.hasUnsavedChanges()) {
      this.visible = false;
    }
  }

  public submit(data?: any): void {
    if (
      FormHelper.isSubmitting(this.formConfig) || // Already submitting
      (!!data && !this.form) || // No data to submit
      !this.checkFormValidity()
    ) {
      // Don't submit invalid data
      return;
    }

    this.action(data ? data : this.form.value);
  }

  public hasUnsavedChanges(): UnsavedChangesResponse {
    return this.unsavedChangesWarningService.defaultHasUnsavedChanges(
      this.form,
      FormHelper.isSubmitting(this.formConfig),
    );
  }

  @HostListener('window:beforeunload', ['$event'])
  @HostListener('window:pagehide', ['$event'])
  public refreshUnsavedChangesPopup(event: any): string | boolean {
    return this.unsavedChangesWarningService.handleBrowserRefresh(this.form, event);
  }

  protected onOpen(): void {
    this.visible = true;
  }

  protected doAction(data: any, options?: ListOptions): Observable<any> {
    return this.apiService[this.getAction](data, options) as Observable<HttpRequest<any>>;
  }

  protected action(data: any, options?: ListOptions): void {
    this.formConfig.state = FormState.isSubmitting;
    this.formConfig.submitIcon$.next('fas fa-spinner fa-pulse');

    this.doAction(data, options)
      .pipe(
        finalize(() => {
          this.formConfig.state = FormState.isIdle;
          this.formConfig.submitIcon$.next('fa fa-check');
        }),
      )
      .subscribe((response: EntityModel) => {
        this.showSuccessToast();

        this.onSuccessfulSubmit(response);
      });
  }

  protected showSuccessToast(): void {
    const title = this.translateService.instant('toast.success.title');
    const entityName = this.translateService.instant(
      `dxp.${this.entityConfig.name || this.entityConfig.name}.entity_name`,
    );
    const message = this.translateService.instant(`toast.success.${this.getAction}`, { entity: entityName });

    this.notificationService.success(title, message);
  }

  protected onSuccessfulSubmit(response: EntityModel): void {
    // This method serves purely as a hook
  }

  protected checkFormValidity(): boolean {
    if (this.form.invalid) {
      FormUtil.markAsTouched(this.form);
    }

    return this.form.valid;
  }
}
